.feed-invitation-container-card {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--color-white-100);
  border-radius: var(--border-radius-default);
  color: var(--color-gray-15);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 0 16px;
  padding: 16px;
  text-align: center;

  div {
    width: 100%;
  }

  .header-icon {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;
    justify-content: center;

    svg {
      color: var(--color-orange-54);
      height: 48px;
      width: 48px;
    }
  }

  .email {
    color: var(--color-gray-75);
  }

  .invited {
    margin: 4px 0;
  }

  .description {
    text-align: left;
  }

  .accept-decline {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }

  .action-container {
    display: flex;
    width: fit-content;

    > div {
      align-self: center;
      margin-left: 8px;
      width: fit-content;
    }
  }

  .no-admin-alert {
    text-align: left;
  }

  .workspace {
    align-items: center;
    background: var(--color-white-100);
    border: 1px solid var(--color-gray-88);
    border-radius: var(--border-radius-default);
    cursor: pointer;
    display: flex;
    gap: 16px;
    padding: 8px;

    button {
      margin-left: auto;
      visibility: hidden;
    }

    &:hover {
      border: 1px solid var(--color-blue-32);
      outline: 1px solid var(--color-blue-32);

      button {
        visibility: visible;
      }
    }

    .avatar {
      align-items: flex-start;
      background: var(--color-white-100);
      border: 2px solid var(--color-gray-88);
      border-radius: var(--border-radius-default);
      display: flex;
      gap: 12px;
      padding: 8px;
      width: fit-content;

      img {
        width: 24px;
      }
    }
  }
}

.feed-invitation-accepted {
  visibility: visible !important;
}

.feed-invitation-container {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  justify-content: flex-start;
  overflow: auto;
  padding: 100px 0;
  width: 100%;

  .feed-invitation-container-card {
    align-self: center;
    width: 650px;
  }
}

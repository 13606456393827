.rule-operator-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 16px;
  padding: 0;
}

.rule-wrapper {
  background-color: var(--color-white-100);
  border-radius: var(--border-radius-default);
  padding: 16px;
  width: 100%;

  .rule-name {
    margin: 0 0 16px;
  }

  .rule-field-wrapper {
    background: var(--color-beige-86);
    border-radius: var(--border-radius-small);
    padding: 16px;
  }

  .rule-if-group,
  .rule-then-group {
    background-color: var(--color-beige-93);
    border-radius: var(--border-radius-medium);
    padding: 16px;

    .rule-field-wrapper {
      + .rule-operator-wrapper {
        background-color: var(--color-beige-86);
        border-radius: var(--border-radius-medium);
        margin: 0 0 16px;
        padding: 0 16px 16px;
      }

      &:last-of-type {
        border: solid 3px var(--color-pink-53);

        + .rule-operator-wrapper {
          background-color: var(--color-orange-54);
          margin: 0 0 16px;
        }
      }
    }
  }

  .rule-if-title,
  .rule-then-title {
    @mixin typography-subtitle1;
    margin: 0 0 16px;
  }
}

.rules-toolbar {
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  .rulesToolbarSection {
    align-items: center;
    display: flex;
    gap: 8px;
  }
}

.itemCard {
  background-color: transparent;
  min-width: 500px;
  position: relative;

  &.listItemUnread {
    :global(.card) {
      background-color: var(--color-blue-98);
    }

    .clusterCard {
      &::before {
        background-color: var(--color-blue-81);
        border-radius: var(--border-radius-small);
        bottom: 6px;
        content: '';
        left: 6px;
        overflow: hidden;
        position: absolute;
        right: auto;
        top: 6px;
        width: 5px;
      }
    }
  }

  .clusterCardLeft {
    align-self: center;
    display: flex;
    flex: 0 0 auto;
    gap: 0 8px;
    justify-content: space-between;
  }

  .sharedItemCardMiddle {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
  }

  .clusterCardRight {
    align-items: flex-end;
    display: flex;
    flex: 0 0 150px;
    flex-direction: column;
    text-align: right;
    width: 150px;

    > div {
      width: 100%;

      button,
      button span {
        max-width: 100%;
      }
    }
  }

  .bulletSeparator > div {
    color: var(--color-gray-75);
  }

  .clusterCardRating {
    align-items: flex-end;
    display: flex;
    gap: 3px;
    justify-content: flex-end;

    .publishedLabel > button > div > svg {
      color: var(--color-green-35);
    }
  }

  .checkbox {
    align-items: center;
    align-self: center;
    display: flex;
    flex: 0 0 42px;
    flex-direction: row;
    margin: 0 -8px 0 0;
    opacity: .32;
    overflow: hidden;
    transition: opacity 225ms cubic-bezier(0, 0, .2, 1) 0ms;
  }

  &.listItemSelected {
    :global(.card) {
      background-color: var(--color-blue-98);

      &:not(:hover) {
        border-color: var(--color-blue-90);
      }
    }

    .checkbox {
      opacity: 1;
    }
  }

  &:hover {
    .checkbox {
      opacity: 1;
    }
  }
}

.listTitle {
  @mixin typography-subtitle2;
  color: var(--color-gray-15);
  padding: 16px 20px 16px 18px;
  text-transform: uppercase;

  sup {
    background-color: var(--color-green-35);
    border-radius: var(--border-radius-large);
    color: var(--color-white-100);
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-size: 50%;
    line-height: 0;
    padding: 4px 6px;
    position: relative;
    top: -1em;
    vertical-align: baseline;
  }
}

.listMainAction {
  padding: 0 16px;
}

.listWrapper {
  --drawerListIconSize: 18px;
  --drawerexpandIconSize: 14px;

  display: flex;
  flex: 1;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 16px 6px;
  position: relative;

  &:global(.MuiList-root) {
    padding: 16px 6px;
  }

  &.listFooter {
    flex: 0 0 auto;
    justify-content: flex-end;
    padding: 16px 6px;
    position: relative;
  }

  .listCollapseWrapper {
    min-height: auto !important;
  }

  .linkList {
    color: inherit;
    text-decoration: none;
  }

  .listItem {
    background-color: unset;
    border: solid 1px var(--color-beige-93);
    border-radius: var(--border-radius-max);
    display: flex;
    height: 42px;
    margin: 0;
    padding: 10px 15px;
    position: relative;
    text-align: left;
    width: 100%;

    .listIcon {
      color: var(--color-gray-37);
      display: flex;
      font-size: var(--drawerListIconSize);
      margin: 0 10px 0 0;
    }

    .listIconFeed {
      color: var(--color-green-35);
    }

    .listLabel {
      flex: 1 1 auto;
      margin: 0;
      min-width: 0;

      span {
        @mixin text-truncate block;
        @mixin typography-body1;
        cursor: pointer;
      }

      &.listLabel_plainText {
        padding: 0 0 0 4px;
      }
    }

    .listItemCount {
      @mixin typography-body1;
      color: var(--color-gray-37);
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      background-color: var(--color-beige-86);
    }

    &.listItem_containsCount {
      padding: 10px 48px 10px 15px;
    }

    &.listItem_active,
    &.listItem_active:hover {
      background-color: var(--color-gray-15);
      color: var(--color-white-100);

      .listIcon {
        color: var(--color-white-100);

        &.listIconInvitedFeed {
          color: var(--color-orange-54);
        }
      }

      .listItemCount {
        color: var(--color-white-100);
      }
    }

    &.listItem_empty {
      padding: 5px 5px 5px 15px;

      .listLabel {
        color: var(--color-gray-59);

        span {
          cursor: text;

          em {
            @mixin typography-caption;
            font-style: italic;
          }
        }
      }

      &:hover {
        background-color: var(--color-white-100);
      }
    }

    &.listItemInvited {
      font-style: italic;
    }

    &.listHeader {
      align-items: center;
      cursor: pointer;
      overflow: hidden;

      .listAddListButton {
        display: none;
        margin: 0 -12px 0 0;
      }

      &:hover {
        .listAddListButton {
          display: block;
        }
      }
    }
  }

  .groupList {
    border: solid 1px var(--color-blue-98);
    border-radius: var(--border-radius-zero) var(--border-radius-zero) var(--border-radius-medium) var(--border-radius-medium);
    border-width: 0 1px 1px;
    margin: 0 0 0 1px;
    padding: 6px;
    width: 100%;
  }
}

.listWrapperScrollWrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;

  &::before,
  &::after {
    background-color: var(--color-beige-86);
    content: '';
    height: 2px;
    position: absolute;
  }

  &::before {
    display: block;
    left: 6px;
    right: 20px;
    z-index: var(--z-index-1);
  }

  &::after {
    bottom: 0;
    left: 6px;
    right: 20px;
  }

  .listWrapper {
    overflow: auto;
    padding: 6px 6px 0;

    &::before {
      background-color: var(--color-beige-93);
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: var(--z-index-2);
    }

    &::after {
      background-color: var(--color-beige-93);
      content: '';
      display: block;
      flex-shrink: 0;
      height: 2px;
      margin-top: auto;
      position: relative;
      z-index: var(--z-index-2);
    }
  }
}

.team-details {
  display: flex;
  gap: 32px;
}

.team-details-column {
  width: 100%;
}

.StyledAvatarEditButton {
  width: 100%;
}

.link-engagement-details {
  margin: 16px 0 0;
}

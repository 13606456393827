.status-label {
  align-items: center;
  display: flex;
  gap: 2px;

  :global(.check-icon) {
    font-size: var(--iconSizeDefault);
  }

  small {
    @mixin typography-subtitle2;
    color: var(--color-gray-37);
    margin: 3px 0 0 3px;
  }
}

.status-message {
  margin: 6px 0 0 28px;
  width: calc(100% - 28px);
}

.settings-translated-statuses {
  .settings-translated-rows {
    border-bottom: solid 2px var(--color-gray-88);
    display: flex;
    gap: 16px;
    padding: 16px 0;

    &:last-of-type {
      border-bottom: 0;
    }

    > div {
      display: flex;
      flex: 1 1 50%;
      flex-direction: column;
      gap: 16px;
    }
  }

  .settings-translated-row-header {
    > div {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 8px;
    }
  }
}

.edit-status-dialog {
  .edit-status-label {
    align-items: center;
    display: flex;
    gap: 16px;

    > div {
      flex: 1 1 auto;
    }
  }
}

.checkboxWrapper {
  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 1px;
    user-select: none;

    span {
      color: var(--color-gray-15);
    }

    input[type='checkbox'] {
      appearance: none;
      display: none;
    }

    .checkboxIcon {
      border-radius: 50px;
      font-size: var(--iconSizeDefault);
      padding: 9px;
      transition: color 100ms linear, background-color 100ms linear, border-color 100ms linear;

      &.checked {
        color: var(--color-blue-54);
      }
    }

    &.disabled {
      color: var(--color-gray-59);

      span {
        color: var(--color-gray-75);
      }

      &:hover {
        background-color: transparent;
        border-radius: 50px;
        color: var(--color-gray-59);
      }
    }

    &:hover {
      .checkboxIcon {
        background-color: var(--color-blue-98);
        color: var(--color-blue-54);

        &.checked {
          color: var(--color-blue-38);
        }
      }
    }
  }
}

.mediaArticlesDisplay {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .explainerCard {
    opacity: 1;

    &.explainerCardDimmed {
      opacity: .15;
      transition: opacity 225ms cubic-bezier(0, 0, .2, 1) 0ms;

      &:hover {
        opacity: 1;
      }
    }
  }
}

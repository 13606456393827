.settings-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  .settings-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: auto;
    padding: 16px 16px 32px;

    .appstore-badges {
      display: flex;
      gap: 16px;

      a {
        /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
        color: unset;
        display: inline-flex;
        overflow: hidden;

        svg {
          &.appstore-apple {
            height: 40px;
            width: 120px;
          }

          &.appstore-play {
            height: 40px;
            width: 135px;
          }
        }
      }
    }

    .setting-details-wrapper {
      max-width: var(--columnWidthLarge);
      width: 100%;

      .content-translation-wrapper {
        border: 1px solid var(--color-gray-88);
        border-radius: var(--border-radius-medium);
        margin-top: 8px;
        padding: 8px;

        .content-translation-title {
          align-items: center;
          cursor: pointer;
          display: flex;
          gap: 8px;
          justify-content: flex-start;

          strong {
            flex: 1 1 auto;
          }

          span.setting-content-container-title-expand {
            flex: 0 0 30px;
          }
        }

        .content-translation-details {
          background-color: var(--color-beige-93);
          border-radius: var(--border-radius-medium);
          margin: 8px 0;
          padding: 16px;

          .content-translation-details-default {
            margin: 0 0 16px;
          }
        }

        &.content-translation-wrapper-collapsed {
          .content-translation-details {
            display: none;
          }
        }

        .default-bot-response {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 8px 0 0;

          .default-bot-response-content {
            display: none;
          }

          &.default-bot-response-expanded {
            .default-bot-response-content {
              display: block;
            }
          }
        }
      }

      .setting-content-container {
        background-color: var(--color-white-100);
        border-radius: var(--border-radius-default);
        padding: 16px;
        position: relative;

        .setting-content-container-title {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin: 0 0 8px;
          min-height: 34px;

          > span {
            @mixin typography-subtitle1;
            align-items: center;
            display: flex;
            flex: 1 1 auto;
            gap: 2px;

            :global(.check-icon) {
              font-size: var(--iconSizeDefault);
            }

            &.tooltip-icon {
              color: var(--color-gray-37);
              flex: 0 0 var(--iconSizeSmall);
              font-size: var(--iconSizeSmall);
              margin: 0 0 1px 6px;

              svg {
                display: inline-block;
              }
            }
          }

          .setting-content-container-actions {
            align-items: center;
            display: flex;
            gap: 8px;
          }
        }

        .setting-content-container-inner {
          background-color: var(--color-beige-93);
          border-radius: var(--border-radius-medium);
          margin: 0 0 16px;
          padding: 16px;

          + .setting-content-container-inner {
            margin: 16px 0 0;
          }

          &:last-of-type {
            margin: 0;
          }
        }

        .setting-content-container-inner-accent {
          background: var(--color-beige-86);
          border-radius: var(--border-radius-small);
          padding: 16px;

          + .setting-content-container-inner-accent {
            margin: 16px 0 0;
          }
        }

        + .setting-content-container {
          margin: 16px 0 0;
        }

        .teammembers-table {
          .teammembers-table-identity {
            align-items: center;
            display: flex;
            gap: 8px;

            .teammembers-table-identity-pending {
              border: solid 2px var(--color-gray-88);
              border-radius: var(--border-radius-default);
              color: var(--color-orange-54);
              font-size: var(--iconSizeDefault);
              height: 40px;
              padding: 6px;
              text-align: center;
              width: 40px;
            }

            .teammembers-table-identity-status {
              align-items: flex-start;
              display: flex;
              flex: 1 1 auto;
              flex-direction: column;
              justify-content: center;

              &.teammembers-table-identity-invited {
                color: var(--color-orange-31);
              }

              .text-truncate {
                max-width: 320px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            small {
              @mixin typography-body2;
            }
          }

          .main-cell {
            max-width: 480px;
            overflow: hidden;
          }

          .date-cell {
            min-width: 144px;
            white-space: nowrap;
          }
        }

        &.user-info-primary {
          flex: 3;

          .user-info-name {
            align-items: center;
            display: flex;
            gap: 8px;
            margin-bottom: 8px;
          }

          .contact-info {
            align-items: center;
            color: var(--color-gray-37);
            display: flex;
            flex-flow: wrap row;
            gap: 8px;
            margin: 0 0 16px;
          }
        }
      }

      :global(.MuiTableContainer-root) {
        background-color: var(--color-white-100);
        border-radius: var(--border-radius-default);
        border-top: 0;
      }

      .table-toolbar {
        align-items: center;
        background-color: var(--color-blue-98);
        border-radius: var(--border-radius-medium);
        color: var(--color-blue-54);
        display: flex;
        margin: 0 0 16px;
        padding: 8px 16px;

        .table-toolbar-summary {
          @mixin typography-button;
          flex: 1 1 auto;
        }

        .table-toolbar-actions {
          flex: 0;
        }
      }

      table {
        tbody {
          tr {
            &:last-of-type {
              td,
              th {
                border-bottom: 0;
              }
            }
          }
        }
      }

      .setting-content-list {
        li {
          align-items: flex-start;
          border-bottom: solid 1px var(--color-gray-88);
          display: flex;
          gap: 16px;
          margin: 0 0 8px;
          padding: 8px 0 16px;
          position: relative;

          &.setting-content-list-language {
            align-items: center;
          }

          &.current-active-item {
            &::before {
              background-color: var(--color-beige-47);
              bottom: 8px;
              content: '';
              left: -8px;
              position: absolute;
              top: 0;
              width: 4px;
            }
          }

          &.clickable-list-item {
            cursor: pointer;
          }

          &:last-of-type {
            border-bottom: 0;
            margin: 0;
            padding: 8px 0 0;
          }

          > div {
            flex: 1 1 auto;

            &.team-avatar {
              flex: 0 0 auto;
            }

            &.setting-content-list-actions {
              flex: 0 0 auto;
            }
          }
        }
      }

      .setting-content-form-wrapper {
        margin: 16px 0 0;

        .setting-content-form-field + .setting-content-form-field {
          margin: 16px 0 0;
        }
      }

      .tipline-admin-settings {
        margin: 32px 0 0;
      }

      .user-info-edit {
        display: flex;
        gap: 32px;

        .user-info-primary {
          width: 100%;
        }

        .user-info-avatar {
          flex: 0;
          flex-shrink: 0;
          justify-content: center;
          margin: 0 16px 0 0;

          .StyledAvatarEditButton {
            margin: 8px 0 0;
            width: 100%;
          }
        }
      }
    }
  }
}

.tipline-settings-toggle {
  background-color: var(--color-beige-93);
  border-radius: var(--border-radius-medium);
  display: flex;
  justify-content: center;
  margin: 0 0 16px;
  padding: 16px;
  text-align: center;
}

.tipline-settings-menu-count-alert {
  margin: 0 0 16px;
}

.similarity-component-input {
  display: inline-block;
  max-width: 100px;
  padding: 0 10px;
}

.bot-designer {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .bot-designer-menu {
    flex: 0 0 250px;
    margin: 0;
  }

  .bot-designer-content {
    flex: 1 1 auto;
    margin: 0;
  }

  .tipline-menu-options {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .tipline-settings-menu-title {
      width: 100%;
    }

    .tipline-menu-option {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .tipline-menu-option-content,
      .tipline-menu-option-actions {
        align-items: center;
        display: flex;
        gap: 10px;
      }
    }
  }
}

.install-bot-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 240px 0;
}

/* stylelint-disable no-descending-specificity */
.search-form {
  align-items: center;
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  gap: 8px;
  margin: 12px 0 0;

  /* Max width is with max with of form field + gap + with of settings buttons */
  max-width: 542px;

  .search-form-config {
    align-items: center;
    background-color: var(--color-beige-86);
    border-radius: var(--border-radius-default);
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 48px;
    padding: 8px;

    .search-form-config-hidden {
      display: none;
    }
  }
}

.search-field-wrapper {
  align-items: center;
  display: flex;
  flex: 1 1 0;
  min-width: 200px;
  position: relative;

  .search-clear-button,
  .search-expand-button {
    bottom: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .search-clear-button {
    left: 6px;
  }

  .search-expand-button {
    right: 6px;
    visibility: hidden;
  }

  .search-field {
    flex: 1 1 0;

    &.search-field-active {
      input {
        border-color: var(--color-blue-54);
      }
    }

    &.search-field-expandable {
      input {
        padding-right: 38px;
      }
    }
  }

  &:hover {
    .search-expand-button {
      visibility: visible;
    }
  }
}

.search-expanded-popover {
  :global(.MuiPopover-paper) {
    background-color: var(--color-white-100);
    border-radius: var(--border-radius-default);
    box-shadow: var(--dropShadowMedium);
    margin: 16px 0 0 2px;
    padding: 16px;
    width: 450px;
  }

  .search-expanded-popover-buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    justify-content: space-between;
    margin: 8px 0 0;

    &.submit-search-expanded {
      flex-direction: row;
    }
  }
}

.close-media-button {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: var(--z-index-1);
}

.filters-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .filter-wrapper {
    background-color: var(--color-beige-93);
    border-radius: var(--border-radius-large);
    max-width: 100%;
    min-height: 42px;
    padding: 3px 6px;
    transition: color 100ms linear, background-color 100ms linear, border-color 100ms linear;

    &:hover {
      background-color: var(--color-beige-86);
    }

    .filter-wrapper {
      padding: 0;
    }

    .filter-multidate-wrapper,
    .filter-removable-wrapper {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      max-width: 100%;
      min-height: 36px;

      &.filter-removable-wrapper-icon {
        border-color: var(--color-orange-54);
        margin: 0 4px 0 0;
      }

      .filter-label {
        font-size: var(--font-size-body-2);
      }

      button:disabled {
        margin: 0 -4px; /* negative margins to remove the gap on non-functional and/or buttons */
      }
    }

    .filter-value {
      align-items: center;
      background-color: var(--color-blue-54);
      border-radius: var(--border-radius-default);
      color: var(--color-white-100);
      display: flex;
      font-size: var(--font-size-body-2);
      height: 30px;
      max-width: 100%;
      padding: 0 6px;

      input,
      span:first-of-type {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.filter-value-missing {
        background-color: var(--color-pink-53);
      }

      &.filter-value-removable {
        padding: 0 0 0 6px;
      }
    }

    .filter-error {
      color: var(--color-pink-53);
      display: flex;
      gap: 4px;

      svg {
        font-size: var(--font-size-base);
      }
    }

    .filter-select,
    .filter-input-number {
      font-size: var(--font-size-body-2);
      height: 30px;

      select,
      input {
        font-size: var(--font-size-body-2);
        height: 30px;
        min-height: 30px;
        padding-bottom: 0;
        padding-top: 0;

        + div {
          font-size: var(--font-size-base);
          height: var(--font-size-base);
          width: var(--font-size-base);
        }
      }
    }

    .filter-input-number {
      width: 8em;

      input {
        padding: 0 6px;
      }
    }

    .filter-date {
      border-radius: var(--border-radius-default) var(--border-radius-zero) var(--border-radius-zero) var(--border-radius-default);
      margin: 0 -12px 0 0;

      + .filter-date-remove button {
        margin: 0 4px 0 0;
      }
    }
  }

  .filters-sorting {
    border-right: solid 1px var(--color-beige-86);
    padding: 0 8px 0 0;
  }

  .filters-buttons-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0 0 0 8px;

    &.filters-buttons-wrapper-visible {
      border-left: solid 1px var(--color-beige-86);
    }
  }

  .filter-toggle-and-or-operator {
    font-weight: bold;
  }
}

.save-new-list {
  align-items: center;
  display: flex;
  gap: 8px;
  width: 100%;

  .save-new-list-title {
    flex: 1 1 auto;
  }
}

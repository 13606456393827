.settings-header-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 0 16px;
  max-width: var(--columnWidthLarge);
  width: 100%;

  .title-actions-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 48px;
    width: 100%;

    .title-wrapper,
    .extra-wrapper,
    .actions-container {
      align-items: center;
      align-self: stretch;
      display: flex;
    }

    .title-wrapper {
      align-items: center;
      gap: 16px;
      justify-content: start;

      h5 {
        align-items: center;
        display: flex;
        gap: 4px;
      }
    }

    .extra-wrapper {
      gap: 8px;
    }

    .data-wrapper {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-right: 16px;
    }

    .buttons-wrapper {
      align-items: center;
      display: flex;
      gap: 8px;
      justify-content: flex-start;
    }
  }

  .settings-header-context {
    @mixin typography-body2;
    color: var(--color-gray-37);
    width: 100%;
  }
}

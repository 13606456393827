.device {
  border: solid 2px var(--color-white-100);
  border-radius: var(--border-radius-max);
  color: var(--color-gray-15);
  height: 932px;
  transition: width 225ms cubic-bezier(0, 0, .2, 1) 0ms, height 225ms cubic-bezier(0, 0, .2, 1) 0ms;

  .deviceTop {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;

    .deviceTopDecorationPlaceholder {
      width: 30px;
    }

    .deviceTopDecorationPill {
      align-items: center;
      background-color: var(--color-beige-76);
      border-radius: var(--border-radius-max);
      display: flex;
      height: 16.27px;
      padding: 4.25px;
      width: 56.95px;
    }

    .deviceTopDecorationCircle {
      background-color: var(--color-beige-86);
      border-radius: var(--border-radius-max);
      height: 9.76px;
      width: 9.76px;
    }
  }

  .deviceIdentifier {
    @mixin typography-subtitle1;
    align-items: center;
    border-bottom: solid 2px var(--color-white-100);
    display: flex;
    gap: 16px;
    height: 68px;
    padding: 16px;
  }

  .deviceChatFeed {
    height: 692.14px;
  }

  .deviceInput {
    align-items: center;
    border-top: solid 2px var(--color-white-100);
    display: flex;
    gap: 8px;
    height: 80px;
    padding: 16px;

    .deviceInputTextField {
      width: 100%;
    }
  }

  &.phone {
    width: 450px;

    .deviceHome {
      align-items: center;
      display: flex;
      height: 43.95px;
      justify-content: center;
      padding-bottom: 16px;
      padding-top: 16px;

      .deviceHomeDecoration {
        background-color: var(--color-beige-76);
        border-radius: var(--border-radius-max);
        height: 11.59px;
        width: 45.2px;
      }
    }
  }

  &.tablet {
    height: 900px;
    width: 1000px;
  }
}

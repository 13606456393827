.reorder {
  background-color: var(--color-gray-96);
  border-radius: var(--border-radius-default);
  display: flex;

  span {
    margin: 2px;
  }

  &.vertical {
    flex-direction: column;
    height: 68px;
    width: 34px;
  }

  &.horizontal {
    flex-direction: row;
    height: 34px;
    width: 68px;

    hr {
      height: auto;
      width: 1px;
    }
  }

  &.theme-gray {
    background-color: var(--color-gray-96);

    hr {
      background-color: var(--color-white-100);
    }
  }

  &.theme-white {
    background-color: var(--color-white-100);

    hr {
      background-color: var(--color-gray-96);
    }
  }
}

.media-requests-dialog {
  .columns {
    display: flex;
    flex-direction: row;
    gap: 16px;
    position: relative;

    &::after {
      background-color: var(--color-gray-88);
      bottom: 0;
      content: '';
      left: 50%;
      position: absolute;
      top: 0;
      width: 1px;
    }

    > div {
      flex: 0 0 50%;
      height: 100%;
      max-height: 700px;
      overflow-y: auto;
      padding: 0 16px 0 0;
    }
  }

  .toggle {
    align-items: center;
    background-color: var(--color-beige-93);
    border-radius: var(--border-radius-medium);
    display: flex;
    flex-direction: column;
    margin: 0 0 16px;
    padding: 8px;
  }
}

.bulletSeparator {
  @mixin typography-body1;
  align-items: center;
  color: var(--color-gray-37);
  display: flex;
  flex-wrap: wrap;
  gap: 8px 0;
  line-height: 20px;
  margin: 0 0 2px;

  .detailSpan {
    align-items: center;
    display: flex;
    max-width: 100%;

    > span {
      max-width: 100%;
    }
  }

  .bullet {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-size: 8px;
    margin: 0 8px;
  }

  .icon {
    font-size: var(--iconSizeDefault);
    margin: 0 3px 0 0;
  }

  &.compactBulletSeparator {
    gap: 0 2px;
    margin: 0;

    .bullet {
      margin: 0 4px;
    }
  }

  &.captionTypography {
    @mixin typography-caption;
  }
}

.multiselector-wrapper {
  max-width: 32rem;

  .multiselector-controls {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 16px 8px;

    .multiselector-search-input {
      width: 100%;
    }

    .multiselector-reset {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }

  .multiselector-scroller-loader {
    background-color: var(--overlayWhite);
    height: 100%;
    left: 0;
    max-height: 320px;
    position: absolute;
    right: 0;
    z-index: var(--z-index-1);
  }

  .multiselector-scroller {
    background-color: var(--color-gray-96);
    height: 320px;
    max-height: 320px;
    min-width: 256px;
    overflow-y: auto;
    padding: 16px;

    :global(.MuiFormControlLabel-root) {
      align-items: flex-start;
      margin-right: 0;

      :global(.MuiFormControlLabel-label) {
        -webkit-box-orient: vertical;
        /* stylelint-disable-next-line value-no-vendor-prefix */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        padding: 11px 0 0;
        text-overflow: ellipsis;
      }
    }

    .list-item-category {
      background-color: var(--color-pink-53);
      color: var(--color-gray-75);
      margin-top: 8px;
    }

    .list-item-child {
      margin-left: 16px;
    }
  }

  .multiselector-footer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-end;
    padding: 16px;
  }

  .not-found {
    color: var(--color-gray-75);
    display: flex;
    justify-content: center;
    padding-bottom: 112px;
    padding-top: 112px;
  }
}

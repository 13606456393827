/* Button mixin used only for building out ButtonMain component */
@define-mixin main-button-contained-theme $primary-color: var(--color-blue-54), $secondary-color: var(--color-blue-38), $text-color: var(--color-white-100), $text-secondary: var(--color-white-100) {
  background-color: $primary-color;
  border: solid 2px $primary-color;
  color: $text-color;

  &:hover {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $text-secondary;
  }
}

@define-mixin main-button-outlined-theme $border-primary: var(--color-gray-88), $border-secondary: var(--color-gray-88), $text-primary: var(--color-blue-54), $text-secondary: var(--color-blue-38), $background-hover: var(--color-blue-98) {
  border: solid 2px $border-primary;
  color: $text-primary;

  &:hover {
    background-color: $background-hover;
    border-color: $border-secondary;
    color: $text-secondary;
  }
}

@define-mixin main-button-text-theme $text-primary: var(--color-blue-54), $text-secondary: var(--color-blue-38), $background-hover: var(--color-blue-98) {
  color: $text-primary;

  &:hover {
    background-color: $background-hover;
    color: $text-secondary;
  }
}

@define-mixin scroll-indicator-top $container-padding: 16px, $container-background: var(--color-white-100), $indicator-color: var(--color-beige-86) {
  &::before,
  &::after {
    content: '';
    display: block;
    top: 0;
    z-index: var(--z-index-3);
  }

  &::before {
    background-color: $indicator-color;
    box-shadow: 0 3px 6px 0 rgba(0 0 0 / .45);
    flex: 1 0 2px;
    height: 2px;
    margin: 0 calc(0% - $(container-padding));
    position: sticky;
    transform: translateY(-$container-padding);
  }

  &::after {
    background-color: $container-background;
    height: $container-padding;
    left: 0;
    position: absolute;
    right: 0;
  }
}

@define-mixin typography-definition $size: 14px, $weight: 400, $spacing: .15px, $height: 20px {
  font-size: $(size);
  font-weight: $(weight);
  letter-spacing: $(spacing);
  line-height: $(height);
}

@define-mixin typography-h1 {
  @mixin typography-definition var(--font-size-h1), 300, -1.5px, 112px;
}

@define-mixin typography-h2 {
  @mixin typography-definition var(--font-size-h2), 300, -.5px, 72px;
}

@define-mixin typography-h3 {
  @mixin typography-definition var(--font-size-h3), 400, 0, 56px;
}

@define-mixin typography-h4 {
  @mixin typography-definition var(--font-size-h4), 400, .25px, 42px;
}

@define-mixin typography-h5 {
  @mixin typography-definition var(--font-size-h5), 400, 0, 32px;
  color: currentcolor;
}

@define-mixin typography-h6 {
  @mixin typography-definition var(--font-size-h6), 500, .15px, 32px;
}

@define-mixin typography-subtitle1 {
  @mixin typography-definition var(--font-size-subtitle-1), 700, .15px, 21px;
}

@define-mixin typography-subtitle2 {
  @mixin typography-definition var(--font-size-subtitle-2), 700, .1px, 21px;
}

@define-mixin typography-body1 {
  @mixin typography-definition var(--font-size-body-1), 400, .15px, 20px;
}

@define-mixin typography-body1-bold {
  @mixin typography-definition var(--font-size-body-1), 700, .15px, 20px;
}

@define-mixin typography-body1-italic {
  @mixin typography-definition var(--font-size-body-1), 400, .15px, 20px;
  font-style: italic;
}

@define-mixin typography-body2 {
  @mixin typography-definition var(--font-size-body-2), 400, .15px, 17px;
}

@define-mixin typography-body2-bold {
  @mixin typography-definition var(--font-size-body-2), 700, .15px, 17px;
}

@define-mixin typography-body2-italic {
  @mixin typography-definition var(--font-size-body-2), 400, .15px, 17px;
  font-style: italic;
}

@define-mixin typography-button {
  @mixin typography-definition var(--font-size-button), 500, .4px, 24px;
  color: currentcolor;
  text-transform: none;
}

@define-mixin typography-caption {
  @mixin typography-definition var(--font-size-caption), 400, .4px, 15px;
}

@define-mixin typography-caption-bold {
  @mixin typography-definition var(--font-size-caption), 500, .4px, 15px;
}

@define-mixin typography-overline {
  @mixin typography-definition var(--font-size-overline), 400, 1px, 32px;
  text-transform: uppercase;
}

@define-mixin position $positioning, $top, $right, $bottom, $left {
  bottom: $(bottom);
  left: $(left);
  position: $(positioning);
  right: $(right);
  top: $(top);
}

@define-mixin opacity $value: 1 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' $(value) * 100 ')';
  filter: alpha(opacity = $value * 100);
  opacity: $(value);
}

@define-mixin text-truncate $display {
  display: $(display);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tabWrapper {
  align-items: center;
  background: var(--color-beige-93);
  border-radius: var(--border-radius-default, 8px);
  display: flex;
  gap: 3px;
  margin: 8px;
  min-width: 350px;
  padding: 8px;

  &.variantBanner {
    border-radius: var(--border-radius-zero, 0);
    margin: 0;
  }

  &.wrapContent {
    flex-wrap: wrap;
  }

  .select {
    min-width: 200px;
    width: 100%;
  }
}

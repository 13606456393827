.not-found {
  align-items: center;
  background-color: var(--color-white-100);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto;
  margin-top: 80px;
  max-width: 650px;
  padding: 16px;
  text-align: center;
  width: 100%;
}

.thumbnail {
  border: 2px solid var(--color-gray-88);
  border-radius: var(--border-radius-medium);
  flex-grow: 0;
  flex-shrink: 0;
  height: 92px;
  object-fit: cover;
  width: 92px;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.iconContainer {
  align-items: center;
  display: flex;
  justify-content: center;

  :global(.check-icon) {
    color: var(--color-gray-75);
    font-size: var(--iconSizeDefault);
  }
}

.contentScreen {
  align-items: center;
  background-color: var(--color-gray-15);
  display: flex;
  height: 92px;
  justify-content: center;
  width: 92px;
}

.mediaIcon {
  color: var(--color-gray-75);
  font-size: var(--font-size-body-2);
}

.visibilityOffIcon {
  color: var(--color-white-100);
  font-size: var(--font-size-body-2);
}

.emptyMedia {
  background-color: var(--color-white-100);

  svg {
    color: var(--color-gray-75);
    font-size: var(--iconSizeDefault);
  }
}

.contentScreen,
.thumbnail {
  &.sizeSmall {
    height: 72px;
    width: 72px;

    .thumbnail {
      height: 72px;
      width: 72px;
    }
  }
}

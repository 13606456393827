.task {
  hyphens: auto;
  overflow-wrap: break-word;
  width: 100%;
  word-break: break-word;

  .task-header {
    margin: 0 0 8px;

    sup {
      color: var(--color-pink-53);
    }
  }

  .task-metadata-button {
    display: none;
  }

  .task-map {
    :global(#map-edit) {
      border: dashed 3px var(--color-green-35);
      height: 500px;
      width: 100%;
    }
  }

  .task-footer {
    @mixin typography-caption;
    color: var(--color-gray-37);
    flex: 1 1 auto;
    margin: 8px 0 0;
    text-align: left;
  }
}

.task-conditions {
  align-items: center;
  display: flex;
  gap: 16px;

  > div {
    flex: 1 1 auto;
  }
}

.task-multi-select-wrapper {
  border-top: solid 1px var(--color-gray-88);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0 0;
  padding: 16px 0 0;

  .task-multi-select {
    align-items: center;
    display: flex;
    gap: 8px;

    .task-multi-select-option-input {
      flex: 1 1 auto;
    }
  }

  .task-multi-select-actions {
    display: flex;
    gap: 16px;
  }
}

.task-conditional-multiselect {
  margin-left: 16px;

  :global(.MuiInputBase-root) {
    height: 38px;
    width: 270px;
  }

  :global(#mui-component-select-multiple-conditions)::after {
    /* stylelint-disable-next-line color-function-notation */
    background: linear-gradient(to right, rgba(246, 246, 246, 0), rgba(246, 246, 246, 1) 60%, rgba(246, 246, 246, 1));
    bottom: 1px;
    content: '';
    display: block;
    left: 230px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }

  :global(#mui-component-select-multiple-conditions) {
    height: 24px;
  }

  :global(.MuiChip-root) {
    max-width: 90px;
  }
}

.edit-task-alert {
  margin: 16px 0 0;
}

.select-wrapper {
  align-items: center;
  display: flex;
  gap: 8px;

  .select-container {
    padding: 0 34px 0 0;
    width: 100%;

    .input {
      appearance: none;
      background-color: var(--color-white-100);
      border: 2px solid var(--color-gray-88);
      border-radius: var(--border-radius-default);
      color: var(--color-gray-15);
      font: inherit;
      height: 100%;
      letter-spacing: .15px;
      line-height: 20px;
      min-height: 48px;
      padding: 12px 34px 12px 10px;
      text-overflow: ellipsis;

      /* the select width needs to include the container padding */
      width: calc(100% + 34px);

      &:hover {
        border-color: var(--color-gray-75);
      }

      &::placeholder {
        font: 400 14px var(--fontStackSans);
        letter-spacing: .15px;
        line-height: 20px;
      }

      &:focus,
      &:active {
        border-color: var(--color-blue-54);
        outline: none;

        &::placeholder {
          color: transparent;
        }
      }

      &.input-icon-left {
        padding-left: 38px;
      }

      &.outlined {
        background-color: transparent;
      }

      &.error {
        border-color: var(--color-pink-53) !important;

        &:hover {
          border-color: var(--color-pink-40) !important;
        }

        &:focus {
          border-color: var(--color-pink-53) !important;
        }
      }

      &:disabled,
      &.disabled {
        background-color: var(--color-gray-96);
        color: var(--color-gray-59);
        cursor: not-allowed;
        opacity: 1;

        &.outlined {
          background-color: transparent;
        }

        &:hover {
          border-color: var(--color-gray-88);
        }
      }
    }
  }
}

.select-removeable {
  padding: 0 46px 0 0;
}

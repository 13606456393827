.team-avatar {
  background-color: var(--color-white-100);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid var(--color-gray-88);
  border-radius: var(--border-radius-medium);
  flex: 0 0 auto;
  height: 40px;
  position: relative;
  width: 40px;
}

.input-wrapper {
  .label-container {
    @mixin typography-body2;
    color: var(--color-gray-37);
    display: grid;
    grid-gap: 8px;
    margin-bottom: 4px;

    &.label-container-label.label-container-required {
      grid-template-columns: 2fr 1fr;
    }

    label {
      align-self: end;
      margin-left: 10px;
    }

    .required {
      align-self: end;
      margin-right: 4px;
      text-align: right;
    }

    &.error-label {
      color: var(--color-pink-40);
    }
  }

  .input-container {
    position: relative;

    .input-icon-left-icon,
    .input-icon-right-icon {
      color: var(--color-gray-37);
      font-size: var(--iconSizeDefault);
      height: 24px;
      pointer-events: none;
      position: absolute;
      right: 10px;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    .input-icon-left-icon {
      left: 10px;
      right: auto;
    }
  }

  .help-container {
    @mixin typography-caption;
    margin: 4px 0 0;
    padding: 0 4px 0 10px;

    .error-icon {
      /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
      font-size: 15px;
      margin-right: 4px;
      position: relative;
      top: 3px;
    }

    &.error-label {
      color: var(--color-pink-40);
      white-space: pre-line;

      .help-counter {
        margin: 4px 0 0;
      }
    }
  }

  &.disabled {
    .label-container:not(.error-label),
    .help-container:not(.error-label) {
      color: var(--color-gray-75);
    }

    .input-container {
      .input-icon-left-icon,
      .input-icon-right-icon {
        color: var(--color-gray-75);
      }
    }
  }
}

.form-footer-actions {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: flex-end;

  .form-footer-actions-secondary {
    flex: 1 1 auto;
  }

  .form-footer-actions-context {
    @mixin typography-caption;
    color: var(--color-gray-37);
    flex: 1 1 auto;
    text-align: left;
  }
}

.form-inner-wrapper {
  background-color: var(--color-beige-93);
  border-radius: var(--border-radius-medium);
  padding: 16px;

  &.form-inner-sticky {
    margin: 0 0 16px;
    position: sticky;
    top: -16px;
    z-index: var(--z-index-2);
  }

  .form-inner-wrapper {
    background: var(--color-beige-86);
    border-radius: var(--border-radius-small);
    padding: 16px;
  }

  + .form-inner-wrapper,
  + .form-footer-actions,
  + .form-fieldset-field {
    margin-top: 16px;
  }
}

.form-fieldset {
  .form-fieldset-title {
    @mixin typography-subtitle2;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;

    .form-fieldset-title-extra {
      @mixin typography-caption;
    }
  }

  .form-fieldset-field {
    margin-bottom: 16px;

    &.form-autocomplete-create {
      display: flex;
      gap: 8px;

      :global(.MuiAutocomplete-root) {
        flex: 1 1 auto;
      }

      button {
        flex: 0 0 auto;
        margin: 26px 0 0;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  + .form-fieldset {
    margin-top: 16px;
  }
}

.select-chip {
  margin: 0 8px;
}

.language-picker-wrapper {
  min-width: 230px;
}

:global(.MuiFormHelperText-root).mui-helper-error {
  color: var(--color-pink-53);
}

.mui-icon-select :global(.MuiSelect-select) {
  align-items: center;
  display: flex;
  height: 48px;
  padding: 0 24px 0 8px;
}

.feed-collab-card {
  background: var(--color-white-100);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.feed-collab-input {
  display: flex;
  flex: auto;
  flex-direction: row;
  gap: 8px;
}

.feed-collab-add-button {
  margin-top: 5px;
}

.feed-collab-invites {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feed-collab-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.feed-collab-icon {
  border: 2px solid var(--color-gray-88);
  border-radius: var(--border-radius-default);
  box-sizing: border-box;
  display: flex;
  font-size: var(--iconSizeDefault);
  height: 40px;
  padding: 6px;
  width: 40px;
}

.invitationSentIcon {
  color: var(--color-orange-54);
}

.invitationNewIcon {
  color: var(--color-gray-37);
}

.invitationSentLabel {
  color: var(--color-orange-31);
}

.feed-collab-label {
  flex: auto;
}

.feed-collab-organizer {
  color: var(--color-gray-75);
}

.feed-collab-text-field {
  flex: auto;
}

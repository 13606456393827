.articleCard {
  border-radius: var(--border-radius-default);
  display: block;
  min-width: 500px;

  .articleCardDescription {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
  }

  .cardRight {
    align-items: flex-end;
    display: flex;
    flex: 0 0 150px;
    flex-direction: column;
    text-align: right;
    width: 150px;

    .cardRightTop {
      display: flex;
      gap: 3px;
      justify-content: flex-end;
      width: 150px;

      .cardRightTopRating {
        width: calc(100% - 33px);

        button,
        button span {
          max-width: 100%;
        }
      }

      .cardRightTopPublished {
        flex: 0 0 30px;
      }
    }
  }
}

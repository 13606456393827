.feedBlankState {
  background: var(--color-white-100);
  border-radius: var(--border-radius-zero) var(--border-radius-zero) var(--border-radius-default) var(--border-radius-default);
  padding-bottom: 32px;
  text-align: center;

  h6 {
    margin: 16px;
  }

  p {
    margin: auto;
    width: 242px;
  }

  svg {
    font-size: var(--iconSizeBig);
  }
}

.report-designer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  .report-header-bar {
    align-items: center;
    background-color: var(--color-white-100);
    border-radius: var(--border-radius-default);
    display: flex;
    flex: 0 0 64px;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    margin: 16px 16px 0;
    padding: 16px;
    width: auto;

    .report-header-meta {
      display: flex;
      gap: 32px;
    }

    .report-actions {
      align-items: center;
      display: flex;
      gap: 8px;
      justify-content: space-between;
    }
  }

  .report-designer {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    height: 400px;
    margin: 0 16px;
    max-height: 100%;
    overflow: hidden;

    .report-preview,
    .report-editor {
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      overflow: auto;
      padding: 0 16px 16px;

      h6 {
        align-items: center;
        display: flex;
        margin: 0 0 16px;
      }
    }

    .report-editor {
      padding: 0 16px 16px 0;

      .report-designer-form {
        background-color: var(--color-white-100);
        border-radius: var(--border-radius-default);
        padding: 16px;

        .report-designer-form-container {
          background-color: var(--color-beige-93);
          border-radius: var(--border-radius-medium);
          padding: 16px;
          position: relative;

          .report-designer-form-container-title {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0 0 8px;
            min-height: 34px;

            > span {
              @mixin typography-subtitle1;
              align-items: center;
              display: flex;
              flex: 1 1 auto;
              gap: 2px;

              :global(.check-icon) {
                font-size: var(--iconSizeDefault);
              }
            }
          }

          .report-rating-wrapper {
            background: var(--color-beige-86);
            border-radius: var(--border-radius-small);
            margin: 0 0 16px;
            padding: 16px;

            .report-rating-headline {
              display: flex;
              gap: 8px;

              > button {
                align-self: center;
              }

              > div {
                flex: 1 1 auto;
              }
            }
          }

          .report-image-buttons {
            display: flex;
            gap: 8px;
          }

          + .report-designer-form-container {
            margin: 16px 0 0;
          }

          &.report-designer-form-disabled {
            &::after {
              background-color: var(--color-gray-15);
              border-radius: var(--border-radius-default);
              bottom: 0;
              content: '';
              cursor: not-allowed;
              left: 0;
              opacity: .2;
              position: absolute;
              right: 0;
              top: 0;
              z-index: var(--z-index-2);
            }
          }
        }
      }
    }
  }

  .report-designer-preview {
    margin: 0 auto;

    .message-preview {
      background-color: var(--color-white-100);
      border-radius: var(--border-radius-default);
      color: var(--color-gray-15);
      line-height: 1.5em;
      margin-bottom: 16px;
      margin-top: 16px;
      padding: 16px;
      width: 502px;
    }

    .visual-card-preview {
      position: relative;
    }

    .content-screen {
      align-items: center;
      background-color: var(--color-gray-15);
      border: solid 2px;
      color: var(--color-white-100);
      display: flex;
      flex-direction: column;
      height: 500px;
      justify-content: space-between;
      padding: 64px 40px 16px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 500px;
      z-index: var(--z-index-10);
    }

    .content-screen-icon {
      color: var(--color-pink-53);
      font-size: var(--font-size-h1);
    }
  }
}

.correction-link {
  align-items: center;
  display: inline-flex;
  gap: 4px;
}

.tags-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  text-align: center;
  user-select: none;
  width: 100%;
}

.tags-loader {
  background-color: var(--overlayWhite);
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: var(--z-index-1);
}

.articlesSidebar {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  width: 100%;

  .articlesSidebarTopBar {
    background: var(--color-white-100);
    display: flex;
    gap: 16px;
    height: 62px;
    justify-content: flex-start;
    padding: 16px;
  }

  .articlesSidebarScroller {
    @mixin scroll-indicator-top 16px, var(--color-white-100), var(--color-beige-86);
    overflow-y: auto;
    padding: 16px;
    position: relative;
    width: 100%;
  }

  .articlesSidebarNoArticle {
    border: 2px dashed var(--color-gray-88);
    border-radius: var(--border-radius-default);
    color: var(--color-gray-75);
    font-style: italic;
    margin: 0 0 32px;
    padding: 16px;
    text-align: center;

    :global(.check-icon) {
      font-size: var(--font-size-h4);
    }
  }

  .articlesSidebarList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 8px;
  }

  .articlesSidebarCard {
    border: 1px solid var(--color-gray-88);
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 6px;

    .articlesSidebarCardIcon {
      align-items: center;
      color: var(--color-gray-37);
      display: flex;
      flex-direction: row;
      gap: 3px;

      :global(.check-icon) {
        font-size: var(--font-size-subtitle-1);
      }
    }

    .articlesSidebarCardTitle,
    .articlesSidebarCardDescription {
      -webkit-box-orient: vertical;
      color: var(--color-gray-15);
      display: -webkit-box; /* stylelint-disable-line */
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .articlesSidebarCardTitle {
      @mixin typography-button;
    }

    .articlesSidebarCardDescription {
      @mixin typography-body2;
    }

    .articlesSidebarCardCaption {
      align-items: center;
      display: flex;
      margin: 3px 0 0;

      svg {
        font-size: var(--font-size-base);
      }
    }

    &:hover {
      border-color: var(--color-blue-54);
      border-width: 2px;
      padding: 5px;

      .articlesSidebarCardIcon {
        color: var(--color-blue-54);
        font-weight: bold;
      }
    }

    &.articlesSidebarCardUnavailable {
      opacity: .45;

      &:hover {
        opacity: 1;
      }
    }
  }
}

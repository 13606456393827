.sharedFeedTitle {
  color: var(--color-gray-37);
  text-transform: uppercase;
}

.saveFeedContent {
  --iconSize: 36px;
  display: flex;
  flex: .2 0 650px;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
}

.saveFeedContainer {
  display: flex;
  gap: 32px;
  height: 100%;
  overflow: auto;
  padding: 16px 32px 32px;

  &.saveFeedInvitationRespondContainer {
    .saveFeedContent {
      margin: 32px 0 0;
    }
  }
}

.saveFeedContentNarrow {
  display: flex;
  flex: 0 0 350px;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;

  .saveFeedContentNarrowAction {
    align-self: flex-start;
  }
}

.saveFeedButtonContainer {
  display: flex;
  gap: 16px;
}

.saveFeedButtonMoreActions {
  color: var(--color-blue-54);
}

.saveFeedCard {
  background: var(--color-white-100);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.licenseSection {
  display: grid;
  grid-gap: 16px;
}

.licenseOption {
  align-items: center;
  display: flex;
}

.licenseOptionIcon {
  align-items: center;
  display: flex;
  font-size: var(--iconSize);
  margin-right: 16px;
}

.licenseOptionDescription {
  color: var(--color-gray-37);
}

.licenseOptionDisabled {
  color: var(--color-gray-59);
}

.saveFeedMetadata {
  background-color: var(--color-white-100);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0 0;
  padding: 16px;

  span {
    color: var(--color-gray-37);
    font-size: var(--font-size-body-2);
  }
}

.bulleted-list {
  list-style: disc;
}

.saveFeedLastUpdated {
  align-items: center;
  display: flex;

  svg {
    color: var(--color-blue-54);
    font-size: var(--iconSizeSmall);
  }
}

.invitedEmail {
  line-height: 20px;
}

.dataPointsTitle {
  margin-bottom: 16px;
}

.dataPointsSection {
  border: 1px solid var(--color-gray-88);
  border-radius: var(--border-radius-medium);
  margin-top: 8px;
  padding: 8px 8px 0;

  ul {
    padding: 8px 16px;
  }

  li {
    list-style-type: disc;
  }
}

.dataPointsSectionHeader {
  display: flex;
  gap: 16px;
}

.dataPointsSectionBody {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s;
}

.dataPointsSectionBodyContent {
  padding: 8px 0;
}

.dataPointsSectionExpanded {
  max-height: 800px; /* Just for the animation */
  overflow: visible;
}

.feedContentCard {
  background: var(--color-beige-93);
}

.paragraphMarginTop {
  margin-top: 16px;
}

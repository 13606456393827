.listWidgetWrapper {
  @mixin typography-subtitle2;
  align-items: flex-start;
  border-radius: var(--border-radius-large);
  color: var(--color-gray-15);
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 180px;
  padding: 16px;
  width: 100%;

  .listWidgetEmpty {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    width: 100%;

    span.listWidgetEmptyText {
      @mixin typography-body1;
      color: var(--color-gray-42);
      font-style: italic;
      font-weight: 400;
    }
  }

  .listWidgetItemWrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    margin: 0 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    .listWidgetItemText {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .listWidgetItemValue {
      min-width: 7ch;
      text-align: right;
    }

    &:last-child {
      margin: 0;
    }
  }

  a,
  a:visited,
  a:hover {
    color: var(--color-gray-15);
  }

  ul {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
}

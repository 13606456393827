.drawer {
  --drawerWidth: 256px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: width 225ms cubic-bezier(0, 0, .2, 1) 0ms;
  width: var(--drawerWidth);
  z-index: var(--z-index-4);

  :global(.MuiDrawer-paperAnchorLeft) {
    background-color: var(--color-beige-93);
    border: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: var(--drawerWidth);
  }

  &.drawerClosed {
    width: 0;
  }
}

.blankState {
  @mixin typography-h6;
  color: var(--color-gray-59);
  margin: 40px 0;
  text-align: center;
}

.noMargin {
  margin: 0;
}

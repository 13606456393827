.header-container {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 2fr 1fr;

  :global(.save-button) {
    float: right;
  }
}

.settings {
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.uploader {
  margin: 16px 0;
}

.newsletter-body {
  .rss-feed-url {
    align-items: baseline;
    display: flex;
    gap: 8px;
    margin-bottom: 16px;

    .rss-feed-url-field {
      flex-grow: 1;
    }
  }

  .loading-rss-feed-button {
    align-self: center;
  }

  .switcher {
    margin-bottom: 16px;
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.text-secondary {
  color: var(--color-gray-37);
}

.two-spaced {
  margin-bottom: 16px;
  margin-top: 16px;
}

.tooltip {
  &:global(.MuiTooltip-tooltip) {
    @mixin typography-body2;
    background-color: var(--color-white-100);
    border: 1px solid var(--color-blue-81);
    color: var(--color-gray-15);
    padding: 4px 8px;
  }

  :global(.MuiTooltip-arrow) {
    color: var(--color-white-100);

    &::before {
      border: 1px solid var(--color-blue-81);
      margin-top: -1px;
    }
  }
}

.newsletter-scheduler-container {
  .newsletter-scheduler {
    border-radius: var(--border-radius-medium);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;

    &.newsletter-scheduled {
      background: var(--color-green-97);
    }

    &.newsletter-paused {
      background: var(--color-blue-98);
    }

    .newsletter-scheduler-title {
      align-items: center;
      display: flex;
    }

    .newsletter-scheduler-schedule {
      align-items: center;
      color: var(--color-gray-37);
      display: flex;
    }

    .newsletter-scheduler-send-on,
    .newsletter-scheduler-send-every {
      display: inline-block;
    }

    .newsletter-scheduler-time {
      align-items: flex-end;
      display: inline-flex;
      flex: 1 1 auto;
      gap: 2px;
      padding: 0 0 0 8px;

      > span {
        align-self: center;
        flex: 0 0 auto;
        margin: 0 6px 0 0;
      }

      .newsletter-scheduler-time-input {
        flex: 0 0 105px;
      }

      .select {
        flex: 1 1 auto;
      }
    }

    .newsletter-schedule-actions {
      align-items: center;
      display: flex;
      padding: 0 0 0 2px;

      .newsletter-schedule-meta {
        align-items: center;
        color: var(--color-gray-37);
        display: flex;
        margin: 0 0 0 10px;
      }
    }
  }
}

.error-label,
.error-label:visited {
  color: var(--color-pink-40);
}

.request-card {
  background-color: var(--color-white-100);
  border: 1px solid var(--color-gray-88);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: column;
  gap: 8px;
  outline: solid 1px transparent;
  padding: 8px;
  transition: border-color 225ms cubic-bezier(0, 0, .2, 1) 0ms, outline-color 225ms cubic-bezier(0, 0, .2, 1) 0ms;
  width: 100%;

  .request-card-header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;

    time {
      @mixin typography-body2-bold;
      color: var(--color-gray-37);
    }
  }

  .request-content {
    @mixin typography-body2;
    background-color: var(--color-beige-93);
    border-radius: var(--border-radius-medium);
    gap: 8px;
    padding: 12px;
    width: 100%;
  }

  .request-card-footer {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: space-between;

    .request-card-actions {
      display: flex;
      gap: 8px;
    }
  }
}

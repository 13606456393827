.media-chip {
  align-items: center;
  display: flex;
  gap: 3px;
  padding: 0 .5em 0 .25em;
  position: relative;

  :global(.check-icon) {
    color: var(--color-blue-32);
    font-size: var(--iconSizeSmall);
  }

  &::after {
    background-color: var(--color-blue-98);
    border-radius: var(--border-radius-medium);
    bottom: -.1em;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -.1em;
    z-index: var(--z-index-0);
  }
}

.media-chip-icon {
  z-index: var(--z-index-1);

  svg {
    color: var(--color-gray-15);
    display: block;
  }
}

.media-chip-label {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: var(--z-index-1);
}

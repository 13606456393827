.receipt-wrapper {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 4px 8px;
  width: fit-content;

  .receipt-icon {
    display: inherit;
    font-size: var(--iconSizeSmall);
  }
}

.receipt-gray {
  background-color: var(--color-gray-96);

  .receipt-icon {
    color: var(--color-gray-37);
  }
}

.receipt-green {
  background-color: var(--color-green-97);

  .receipt-icon {
    color: var(--color-green-35);
  }
}

.receipt-yellow {
  background-color: var(--color-orange-95);

  .receipt-icon {
    color: var(--color-orange-54);
  }
}

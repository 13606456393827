.drawerRail {
  --drawerRailWidth: 70px;
  --drawerRailIcon: 26px;

  align-items: center;
  background-color: var(--color-beige-86);
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  text-align: center;
  width: var(--drawerRailWidth);
  z-index: var(--z-index-5);

  .railIconLink {
    background-color: var(--color-beige-86);
    border: 0;
    border-radius: var(--border-radius-default);
    color: var(--color-gray-37);
    cursor: pointer;
    display: block;
    font-size: var(--drawerRailIcon);
    height: 40px;
    position: relative;
    transition: color 300ms cubic-bezier(.19, 1, .22, 1) 0s, background-color 300ms cubic-bezier(.19, 1, .22, 1) 0s;
    width: 40px;

    :global(.check-icon) {
      @mixin position absolute, 50%, auto, auto, 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    &:hover {
      background-color: var(--color-beige-93);
      color: var(--color-gray-15);
    }

    &.railIconLinkActive {
      background-color: var(--color-gray-15);
      color: var(--color-white-100);
    }
  }

  .drawerRailTop,
  .drawerRailMiddle,
  .drawerRailBottom {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: var(--drawerRailWidth);
  }

  .drawerRailTop {
    gap: 16px;

    .railToggleButton {
      border: 0;
      border-radius: var(--border-radius-default);
      cursor: pointer;
      display: block;
      font-size: var(--drawerRailIcon);
      height: 44px;
      padding: 0;
      position: relative;
      width: 44px;

      .railIconLink {
        background-color: var(--color-beige-93);
        bottom: 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 200ms cubic-bezier(0, 0, .2, 1) 0ms;
        width: 100%;
        z-index: var(--z-index-1);

        &:hover {
          color: var(--color-purple-61);
        }
      }

      &.railToggleButtonClosed {
        .railIconLink {
          transform: scaleX(-1);
        }
      }
    }
  }

  .drawerRailBottom {
    gap: 3px;
  }

  .drawerRailMiddle {
    align-self: center;
    flex: 1 1 100%;
    justify-content: center;
  }

  &:hover {
    .drawerRailTop {
      .railToggleButton {
        .railIconLink {
          opacity: 1;
        }
      }
    }
  }
}

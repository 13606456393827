.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .seachHeader,
  .seachResults {
    padding: 16px;
  }

  .seachResults {
    @mixin scroll-indicator-top 16px, var(--color-white-100), var(--color-beige-86);
    overflow-y: auto;
    padding: 16px;
    position: relative;
    width: 100%;
  }
}

.heading {
  color: var(--color-gray-37);
  padding-left: 10px;
}

.chip-container {
  background-color: var(--color-blue-54);
  border-radius: var(--border-radius-max);
  color: var(--color-white-100);
  cursor: default;
  display: inline-flex;
  height: 26px;
  padding: 3px 12px;

  span {
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .delete-button {
    border-radius: var(--border-radius-zero) var(--border-radius-max) var(--border-radius-max) var(--border-radius-zero);
    cursor: pointer;
    display: none;
    height: 26px;
    margin: -3px -12px 0 -6px;
    min-width: 18px;
    overflow: hidden;

    button {
      border-radius: var(--border-radius-zero);
      height: 26px;
      padding: 0;
    }
  }

  &:hover .delete-button {
    display: inline-block;

    button {
      height: 26px;
      min-width: 18px;
      width: 18px;
    }
  }
}

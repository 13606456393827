.no-search-results-add {
  display: flex;
  justify-content: center;
  margin: 64px 0;
}

.searchResultsTitleWrapper {
  align-items: flex-start;
  color: var(--color-gray-37);
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  margin: 0;
  min-width: 250px;

  .searchHeaderSubtitle {
    @mixin typography-caption;
    align-items: center;
    display: flex;
    margin: 0 0 5px;
    text-transform: uppercase;
  }

  .searchHeaderTitle {
    align-items: flex-start;
    display: flex;
    gap: 12px;

    h6 {
      @mixin typography-h6;
      -webkit-box-orient: vertical;
      color: var(--color-gray-15);
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      margin: 0;
      max-height: 5em;
      overflow: hidden;
      white-space: pre-line;
      word-break: break-all;

      > span {
        vertical-align: middle;
      }

      :global(.check-icon) {
        color: var(--color-gray-37);
        font-size: var(--iconSizeDefault);
        margin: 0 6px 2px 0;
        vertical-align: middle;
      }
    }

    .searchHeaderActions {
      align-items: center;
      display: flex;
      gap: 12px;

      .searchHeaderActionButton {
        background: var(--color-gray-96);
        border: 2px solid var(--color-gray-88);
        border-radius: var(--border-radius-default);
        color: var(--color-gray-37);
        font-size: var(--iconSizeSmall);
        height: 32px;
        width: 32px;

        &:hover {
          background: var(--color-blue-98);
          border-color: var(--color-blue-81);
          color: var(--color-gray-37);
        }
      }
    }
  }
}

.search-results-header {
  margin: 16px;

  :global(.search__list-header-filter-row) {
    align-items: flex-start;
    column-gap: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 0;
  }

  :global(.project__description) {
    padding-top: 4px;
  }
}

.search-results-top {
  background-color: var(--color-white-100);
  border-radius: var(--border-radius-default);
  margin: 0 16px 16px;
  padding: 16px;

  .search-results-top-extra {
    margin: 0 0 8px;
  }
}

.search-results-wrapper {
  background-color: var(--color-white-100);
  border: solid 3px var(--color-white-100);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px;
  max-height: 100%;
  overflow: hidden;

  .search-results-toolbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 16px;
    min-height: 38px;

    .search-results-add-item {
      display: flex;
      flex: 1 1 100%;
      justify-content: center;
    }
  }

  .search-results-heading {
    align-items: center;
    color: var(--color-gray-15);
    display: flex;
    flex: 1 1 auto;
    gap: 16px;
    justify-content: space-between;
    text-align: center;

    .search-results-bulk-actions {
      align-items: center;
      display: flex;
      gap: 8px;
    }

    .search-selected {
      color: var(--color-blue-54);
      margin: 0 0 0 8px;
    }

    .search-nav {
      color: var(--color-gray-15);
      cursor: pointer;
      display: flex;
      font-size: var(--iconSizeDefault);
      padding: 0 8px 0 0;
    }

    .search-button-disabled {
      color: var(--color-gray-75);
      cursor: not-allowed;
    }

    .search-pagination {
      align-items: center;
      display: flex;
      white-space: nowrap;
    }
  }

  .search-results-scroller {
    @mixin scroll-indicator-top 16px, var(--color-white-100), var(--color-beige-86);
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
    padding: 16px;
    position: relative;
  }
}

.search-results-table-wrapper {
  border: solid 3px var(--color-white-100);
  border-top: solid 1px var(--color-gray-88);
  display: block;
  max-height: 100%;
  overflow: auto;
  width: 100%;

  table {
    th,
    td {
      font-size: var(--fontSizeDefault);
    }

    thead {
      display: table-header-group;

      tr {
        th {
          background-color: var(--color-white-100);
          border-bottom: solid 1px var(--color-gray-88);
          border-right: solid 1px var(--color-gray-88);
          white-space: nowrap;
          width: auto;

          &:last-child {
            border-right: 0;
          }

          &.unread-status {
            background-color: var(--color-white-100);
            left: 0;
            position: sticky;
            z-index: var(--z-index-3);
          }
        }
      }
    }

    tbody {
      display: table-row-group;

      tr {
        &.search-results-row {
          background-color: var(--color-beige-93);
          position: relative;
          transition: background-color 300ms cubic-bezier(.19, 1, .22, 1) 0s;

          th,
          td {
            border-bottom: solid 1px var(--color-gray-88);
            transition: background-color 300ms cubic-bezier(.19, 1, .22, 1) 0s;
          }

          .unread-status {
            background-color: var(--color-beige-93);
            border-right: solid 1px var(--color-gray-88);
            box-shadow: inset 3px 0 0 0 var(--color-gray-75);
            left: 0;
            position: sticky;
            z-index: var(--z-index-1);
          }

          &.search-results-read {
            background-color: var(--color-white-100);

            .unread-status {
              background-color: var(--color-white-100);
              box-shadow: none;
            }
          }

          &:hover,
          &:hover th,
          &:hover td {
            background-color: var(--color-blue-98);

            .unread-status {
              background-color: var(--color-blue-98);
            }
          }

          &:last-of-type {
            th,
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.noBottomBorder {
  border-bottom: none !important;
}

.search-actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.tooltip {
  &:global(.MuiTooltip-tooltip) {
    @mixin typography-body2;
    background-color: var(--color-white-100);
    border: 2px solid var(--color-purple-61);
    box-shadow: var(--dropShadowMedium);
    color: var(--color-gray-15);
    padding: 4px 8px;

    ul {
      list-style-type: disc;
      margin-left: 16px;
    }
  }

  :global(.MuiTooltip-arrow) {
    color: var(--color-white-100);

    &::before {
      border: 2px solid var(--color-purple-61);
      margin-top: -1px;
    }
  }
}

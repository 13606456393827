.datepicker-container {
  .input {
    background-color: var(--color-white-100);
    border: 2px solid var(--color-gray-88);
    border-radius: var(--border-radius-default);
    color: var(--color-gray-15);
    font: 400 14px var(--fontStackSans);
    letter-spacing: .15px;
    line-height: 20px;
    min-height: 48px;
    padding: 10px;

    &.outlined {
      background-color: transparent;
    }

    &:hover {
      border-color: var(--color-gray-75);
    }

    &::placeholder {
      font: 400 14px var(--fontStackSans);
      letter-spacing: .15px;
      line-height: 20px;
    }

    &:focus {
      border-color: var(--color-blue-54);
      outline: none;

      &::placeholder {
        color: transparent;
      }
    }

    &:disabled,
    &.disabled {
      background-color: var(--color-gray-96);
      color: var(--color-gray-59);
      cursor: not-allowed;
      opacity: 1;

      &.outlined {
        background-color: transparent;
      }

      &:hover {
        border-color: var(--color-gray-88);
      }
    }

    &.error {
      border-color: var(--color-pink-53) !important;

      &:hover {
        border-color: var(--color-pink-40) !important;
      }

      &:focus {
        border-color: var(--color-pink-53) !important;
      }
    }
  }
}

.wrapper {
  display: flex;
  position: relative;
}

.main {
  flex: 1 1 calc(100% - 256px);
  height: 100vh;
  overflow: hidden;
}

.mainContentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &:global(.route__feed) {
    overflow: auto;
  }

  :global(.search-results-wrapper) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.bot-preview-wrapper {
  --preview-actions-width: 450px;
  display: flex;
  flex-flow: row wrap;
  gap: 32px;
  overflow: auto;
  padding: 0 32px 32px;

  .card-and-device-column {
    display: flex;
    flex-flow: column nowrap;
    padding: 16px 0;
  }

  .bot-preview-header {
    display: flex;
    flex-direction: column;
    height: 116px;
    padding: 0 16px;
    width: var(--preview-actions-width);

    .bot-preview-title {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      gap: 8px;

      :global(.check-icon) {
        font-size: var(--iconSizeDefault);
      }

      .beta {
        @mixin typography-body2-bold;
        background: var(--color-green-35);
        border-radius: var(--border-radius-large, 16px);
        color: var(--color-white-100);
        padding: 4px 8px;
      }

      .buttonAsLink {
        background: none !important;
        border: none;
        color: var(--color-blue-54);
        cursor: pointer;
        padding: 0 !important;
        text-decoration: underline;
      }
    }

    .bot-preview-actions-context {
      :global(.check-icon) {
        font-size: var(--iconSizeDefault);
      }

      .select {
        @mixin typography-subtitle2;
        background: transparent;
        border: none;
        color: var(--color-gray-37);
      }
    }
  }

  .bot-preview-action-device-wrapper {
    display: flex;
    margin: -34px 0 0;

    .bot-preview-action-panel {
      display: flex;
      flex-flow: column nowrap;
      margin: 46px 0;
      width: var(--preview-actions-width);

      .bot-preview-action-panel-header,
      .bot-preview-action-panel-content {
        background-color: var(--color-white-100);
        box-shadow: -12px 0 12px -12px rgba(38 38 38 / .25) inset;
      }

      .bot-preview-action-panel-header {
        @mixin typography-subtitle1;
        align-items: center;
        align-self: stretch;
        border-bottom: 2px solid var(--color-beige-93);
        border-radius: var(--border-radius-large, 16px) var(--border-radius-zero, 0) var(--border-radius-zero, 0) var(--border-radius-zero, 0);
        display: flex;
        flex: 0 0 62px;
        gap: 8px;
        height: 62px;
        padding: 16px;

        :global(.check-icon) {
          font-size: var(--iconSizeDefault);
        }
      }

      .bot-preview-action-panel-content {
        border-radius: var(--border-radius-zero, 0) var(--border-radius-zero, 0) var(--border-radius-zero, 0) var(--border-radius-large, 16px);
        flex: 1 1 0;
        overflow: auto;

        &.bot-preview-action-panel-welcome {
          padding: 16px;
        }
      }
    }

    .emptyChatMessage {
      @mixin typography-subtitle1;
      align-items: center;
      color: var(--color-blue-32);
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;
      justify-content: center;
      text-align: center;

      :global(.check-icon) {
        font-size: var(--iconSizeDefault);
      }
    }

    .chat-content {
      height: 100%;
    }
  }

  .settings-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: var(--preview-actions-width);
    padding: 32px 0 0;
    width: var(--preview-actions-width);

    .settings-column-header {
      @mixin typography-subtitle1;
      align-items: center;
      display: flex;
      gap: 8px;

      :global(.check-icon) {
        font-size: var(--iconSizeDefault);
      }

      a {
        @mixin typography-body1;
      }
    }

    .settings-card {
      align-items: flex-start;
      background-color: var(--color-white-100);
      border-radius: var(--border-radius-large, 16px);
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;

      .settings-card-header {
        @mixin typography-subtitle1;
        align-items: center;
        display: flex;
        gap: 8px;

        :global(.check-icon) {
          font-size: var(--iconSizeDefault);
        }
      }

      .settings-card-details {
        align-items: flex-start;
        align-self: stretch;
        background: var(--color-beige-86);
        border-radius: var(--border-radius-default, 8px);
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 8px;

        a {
          color: unset;
        }
      }

      .settings-slider-wrapper {
        margin: 8px 24px;
      }

      .settings-slider-help-text {
        @mixin typography-caption;
      }
    }
  }
}

.bot-preview-select {
  .bot-preview-select-button {
    @mixin typography-subtitle2;

    background-color: transparent;
    border: none;

    color: var(--color-gray-37);
    padding: 4px 0;

    :global(.check-icon) {
      color: var(--color-gray-37) !important;
    }
  }
}

.flyout {
  background-color: var(--color-white-100);
  border: 1px solid var(--color-blue-81);
  border-radius: var(--border-radius-default);
  box-shadow: 0 4px 8px 0 var(--grayShadow);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  .flyout-title {
    @mixin typography-body1-bold;
    color: var(--color-gray-75);
  }

  li {
    button {
      align-items: center;
      color: var(--color-gray-15);
      display: flex;
      gap: 8px;
      padding: 12px;
      width: 100%;
    }

    :global(.check-icon) {
      color: var(--color-gray-15) !important;
      font-size: var(--iconSizeDefault);
    }

    .option {
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 8px;
    }

    &.active {
      button {
        color: var(--color-blue-54) !important;
      }

      :global(.check-icon) {
        color: var(--color-blue-54) !important;
      }
    }

    &:hover {
      background-color: var(--color-gray-88);
    }
  }
}

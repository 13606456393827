.chat-content {
  background-color: var(--color-gray-96);
  border: 0 none;
  border-radius: var(--border-radius-zero) var(--border-radius-zero) var(--border-radius-large) var(--border-radius-large);
  display: flex;
  flex-direction: column-reverse;
  gap: 14px;
  height: 80vh;
  overflow-y: auto;
  padding: 20px 24px 50px;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .date {
    align-self: center;
    margin-top: -1px;
    text-align: center;
  }

  .time {
    align-items: center;
    cursor: default;
    display: flex;
    gap: 4px;

    svg {
      font-size: var(--iconSizeTiny);
    }
  }

  .message {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .user {
      background-color: var(--color-blue-54);
      /* stylelint-disable-next-line color-function-notation */
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .15);
      color: var(--color-white-100);
      max-width: 80%;
      padding: 8px;
      white-space: pre-wrap;
      width: fit-content;
    }

    .bot {
      background-color: var(--color-green-97);
      /* stylelint-disable-next-line color-function-notation */
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .15);
      color: var(--color-gray-15);
      max-width: 80%;
      padding: 8px;
      white-space: pre-wrap;
      width: fit-content;
    }

    .selection {
      background-color: var(--color-blue-98);
      /* stylelint-disable-next-line color-function-notation */
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .15);
      color: var(--color-gray-15);
      max-width: 80%;
      padding: 8px;
      white-space: pre-wrap;
      width: fit-content;
    }

    .sent {
      border-radius: var(--border-radius-large) var(--border-radius-large) var(--border-radius-small) var(--border-radius-large);
    }

    .received {
      border-radius: var(--border-radius-large) var(--border-radius-large) var(--border-radius-large) var(--border-radius-small);
    }

    .delivered {
      color: var(--color-green-35);
      vertical-align: middle;
    }

    /* Styles for different message events */
    .message-event-no-results {
      background-color: var(--color-orange-54);
      color: var(--color-white-100);
    }

    .message-event-report,
    .message-event-search-result,
    .message-event-newsletter,
    .message-event-resource {
      background-color: var(--color-green-35);
      color: var(--color-white-100);
    }

    .message-event-timeout {
      background-color: var(--color-gray-15);
      color: var(--color-white-100);
    }

    .message-event-option-not-available {
      background-color: var(--color-pink-96);
    }

    .message-event-custom-message {
      background-color: var(--color-blue-38);
      color: var(--color-white-100);
    }

    .message-event-status-change {
      background-color: var(--color-blue-98);
    }
  }

  .right {
    align-items: flex-end;
  }
}

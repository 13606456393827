.dialog-window {
  position: relative;

  .dialog-alert {
    margin: 8px;
    width: auto;
  }

  .dialog-title {
    border-bottom: solid 1px var(--color-gray-88);
    padding: 16px 48px 16px 24px;

    h6 {
      -webkit-box-orient: vertical;
      display: -webkit-box; /* stylelint-disable-line */
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dialog-title-choice {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .dialog-title-choice-option {
        :global(.MuiToggleButton-label) {
          display: flex;
          flex-direction: row;
          gap: 3px;

          :global(.check-icon) {
            font-size: var(--iconSizeDefault);
            margin: -4px 0 0;
          }
        }
      }
    }
  }

  .dialog-close-button {
    position: absolute;
    right: 8px;
    top: 16px;
  }

  .dialog-content {
    flex: 1 1 auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: 16px 24px;

    .dialog-alert {
      margin: 0 0 16px;
    }
  }

  .dialog-actions {
    align-items: center;
    border-top: solid 1px var(--color-gray-88);
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    padding: 16px 24px;
  }
}

.slideoutMain {
  box-shadow: -5px 0 5px 2px rgb(0 0 0 / .3);
  height: 100%;
  max-width: 620px;
  min-width: 320px;
  position: fixed;
  text-transform: none;
  top: 0;
  width: 40vw;
  z-index: var(--z-index-9);

  textarea {
    font-weight: normal;
  }

  .slideoutContainer {
    background-color: var(--color-white-100);
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    z-index: var(--z-index-10);
  }

  .slideoutTitle {
    background-color: var(--color-beige-86);

    .slideoutTitleContent {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;
      justify-content: space-between;
      min-height: 80px;
      padding: 10px;

      h6 {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .slideoutBody {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 16px;

    &.slideoutBodyStatic {
      overflow: hidden;
      padding: 0;
    }
  }

  .slideoutFooter {
    background-color: var(--color-beige-93);
    width: 100%;

    .slideoutFooterMain {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      justify-content: space-between;
      min-height: 90px;
      padding: 8px;
    }

    .slideoutFooterContent {
      display: flex;
      gap: 8px;
    }
  }
}

body[dir='rtl'] {
  .slideoutMain {
    left: 0;
    transform: translate3d(-600px, 0, 0);
    transition: all 380ms ease;

    &.slide {
      transform: translate3d(0, 0, 0);
    }
  }
}

body[dir='ltr'] {
  .slideoutMain {
    right: 0;
    transform: translate3d(600px, 0, 0);
    transition: all 380ms ease;

    &.slide {
      transform: translate3d(0, 0, 0);
    }
  }
}

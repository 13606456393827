.aspectRatio {
  background-color: var(--color-gray-15);
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  .innerWrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }

    :global(div.aspect-ratio__overlay) {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: var(--z-index-6);
    }
  }

  .toggleShowHideButton {
    pointer-events: auto;
  }
}

.aspectRatioSkipped {
  position: relative;
}

.aspectRatio,
.aspectRatioSkipped {
  .buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 8px 0 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: var(--z-index-7);
  }
}

.sensitiveScreen {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  color: var(--color-white-100);
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 32px 48px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: var(--z-index-8);

  p {
    margin-bottom: 0;
  }

  .toggleShowHideButton {
    pointer-events: auto;
  }

  .visibilityIcon {
    display: none;
    font-size: var(--iconSizeDefault);
  }

  &.contentWarning {
    background-color: var(--color-gray-15);

    .visibilityIcon.warningIcon {
      display: block;
    }
  }
}

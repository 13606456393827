.team-tasks-list-item {
  align-items: center;
  display: flex;
  gap: 10px;
  margin: 0 0 16px;

  &:last-of-type {
    margin: 0;
  }

  .task-card {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 8px;
    margin: 0 0 16px;

    .task-card-header {
      display: flex;
      gap: 8px;
      justify-content: space-between;

      .task-card-label-description {
        width: 100%;

        .task-card-label {
          align-items: center;
          display: flex;
          gap: 8px;

          .task-card-label-description-index {
            align-items: center;
            background-color: var(--color-beige-86);
            border-radius: var(--border-radius-medium);
            display: flex;
            font-size: var(--fontSizeLarge);
            gap: 3px;
            padding: 8px;
          }

          strong {
            display: block;
            flex: 1 1 auto;
          }
        }

        p {
          @mixin typography-body2;
          margin: 4px 0 0;
        }
      }
    }

    .task-card-actions {
      display: flex;
      gap: 16px;
    }

    .task-card-form {
      margin: 16px 0 0;
    }

    .task-card-condition {
      display: flex;
      gap: 16px;
    }
  }
}

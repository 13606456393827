.feedItemHeader {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.feedItemHeaderMetadata {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;

  h6 {
    -webkit-line-clamp: 1 !important;
  }
}

.feedItemHeaderButtons {
  display: flex;
  gap: 8px;
}

.feedItemHeaderMetadataRow {
  align-items: center;
  display: flex;

  span {
    line-height: var(--iconSizeTiny);
    text-transform: none;
  }

  svg {
    font-size: var(--iconSizeTiny);
  }
}

.feedItemColumns {
  display: flex;
  gap: 16px;
  margin: 16px;
}

.feedItemColumn {
  align-self: flex-start;
  background: var(--color-white-100);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: calc(100vh - 143px);
  overflow-y: auto;
  padding: 16px;
  width: 420px;
}

.feedItemColumn + .feedItemColumn {
  flex: 1;
  justify-content: flex-start;
  overflow-x: hidden;
}

.feedItemTeamCardUnclickable {
  cursor: auto;
  pointer-events: none;
}

.feedTeamFactCheckCard {
  border-bottom: 1px solid var(--color-gray-88);
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 8px;
  padding: 8px 0;
  width: 100%;
}

.feedItemTeamCardHeader {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}

.feedItemTeamCardFooter > div {
  flex-wrap: nowrap !important;
}

.feedTeamCard {
  .feedItemTeamCard {
    cursor: pointer;
    flex-direction: column;
    justify-content: flex-start;

    .feedTeamFactCheckCard:last-of-type {
      border-bottom: 0;
    }

    .feedTeamCardInnerWrapper {
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows 500ms cubic-bezier(.19, 1, .22, 1);
      width: 100%;

      .feedTeamCardInner {
        overflow: hidden;
      }
    }
  }

  &.feedItemTeamCardExpanded {
    .feedItemTeamCard {
      .feedTeamCardInnerWrapper {
        grid-template-rows: 1fr;
      }
    }
  }

  grid-template-rows: 1fr;

  &.feedItemTeamCardSelected > div {
    border: 2px solid var(--color-blue-54);
  }
}

.feedItemTeamsTitle {
  margin: 16px 0;
}

.feedItemTeamsSubtitle {
  margin-bottom: 8px;
}

.feedItemCurrentTeam {
  background: var(--color-beige-93);
  border-radius: var(--border-radius-default);
  padding: 8px;
}

.feedItemTeamCardNotSharing {
  color: var(--color-gray-75);
  flex-wrap: wrap;
  font-size: var(--font-size-body-2);
  font-style: italic;
  margin-top: 8px;
  width: 100%;
}

.feedItemDialog {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.feedItemDialogBox {
  background: var(--color-beige-93);
  border-radius: var(--border-radius-medium);
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
}

.feedItemDialogSubtitle {
  color: var(--color-gray-37);
}

.feedItemDialogSubtitleFactCheck {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.feedItemDialogSubtitleWrapper {
  align-items: center;
  display: flex;
  gap: 8px;

  .feedItemDialogSubtitleFactCheckRating {
    @mixin typography-body2;
    border: solid 2px;
    border-radius: var(--border-radius-default);
    padding: 2px 4px;
  }
}

.feedItemDialogBulletSeparator,
.feedItemDialogBulletSeparatorComponent {
  align-items: center;
  display: flex;
  font-size: var(--font-size-body-2);
  gap: 4px;

  svg {
    font-size: var(--iconSizeTiny);
  }
}

.feedContentNotAvailable {
  color: var(--color-gray-75);
  margin: 64px 0;
  text-align: center;
  width: 100%;
}

.feedItemMediaSectionTitle {
  align-items: center;
  display: flex;
  gap: 8px;
}

.feedItemOneLineText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.feedImportNotInCluster {
  color: var(--color-gray-75);
}

.feedImportLink {
  color: var(--color-blue-32);
  cursor: pointer;
  text-decoration: underline;
}

.feedImportSuccessLink {
  color: var(--color-white-100) !important;
}

.feedImportSearch a {
  text-decoration: none;
}

.feedItemDialogForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
